<div class="knk-single-field">
  @for (part of fieldPath$ | async; track $index) {
    <knk-dropdown
      [loading]="!!!(optionsByIndex$ | async)"
      [ngModel]="part"
      (ngModelChange)="changedFieldAtIndex($event, $index)"
      [options]="
        (optionsByIndex$ | async) ? (optionsByIndex$ | async)![$index] : []
      "
      [disabled]="isDisabled"
    ></knk-dropdown>
  }

  @if ((optionsOnEnd$ | async)?.length) {
    <knk-dropdown
      [ngModel]="null"
      [loading]="(optionsOnEnd$ | async) === null"
      (ngModelChange)="addPropertyFromNavigation($event)"
      [options]="(optionsOnEnd$ | async)!"
    ></knk-dropdown>
  }


  @if (!isDisabled) {
    @if (canDelete) {
      <button
        tuiIconButton
        type="button"
        appearance="flat"
        size="s"
        icon="tuiIconClose"
        (click)="remove()"
      ></button>
    }
  }
</div>
