<div class="knk-simple-expression">
  @if (optionsByIndex$ | async; as optionsByIndex) {
    @for (part of removeIdIfPropertyPathToLookup$ | async; track $index) {
      <knk-dropdown
        [ngModel]="part"
        [search]="true"
        (ngModelChange)="updatePropertyOnIndex($event, $index)"
        [options]="optionsByIndex[$index]"
      ></knk-dropdown>
    }
  }

  @if (expressionOperator$ | async; as expressionOperator) {
    <knk-dropdown
      [ngModel]="expressionOperator"
      [search]="true"
      (ngModelChange)="updateOperator($event)"
      [options]="(operatorOptions$ | async)!"
    ></knk-dropdown>
  }

  @if (!!!(expressionOperator$ | async)) {
    <knk-dropdown
      #lastDropdown
      [options]="(optionsOnEndWithOperators$ | async)!"
      [loading]="!!!(optionsOnEndWithOperators$ | async)"
      [disabled]="!!!(optionsOnEndWithOperators$ | async)"
      [search]="true"
      ngModel
      (ngModelChange)="
        pushToFromOptionsOnEnd($event); lastDropdown.writeValue(null)
      "
    ></knk-dropdown>
  }

  @if ((lookupTableName$ | async) && (expressionOperator$ | async)) {
    <ng-container
      [ngTemplateOutlet]="
        controlSelector
          ? controlSelector(this, 'lookup') || lookupValue
          : lookupValue
      "
      [ngTemplateOutletContext]="{ $implicit: this }"
    ></ng-container>
  }

  @if (
    (expressionOperator$ | async) &&
    !!!(lookupTableName$ | async) &&
    (showValueControl$ | async)
    ) {
    @if (beforeControlTemplate) {
      <ng-container
        [ngTemplateOutlet]="beforeControlTemplate"
        [ngTemplateOutletContext]="{ $implicit: this }"
      ></ng-container>
    }

    @switch (currentSimpleExpressionType$ | async) {
      @case (DataType.Bool) {
        <ng-container
          [ngTemplateOutlet]="
            controlSelector
              ? controlSelector(this, DataType.Bool) || booleanValue
              : booleanValue
          "
          [ngTemplateOutletContext]="{ $implicit: this }"
        ></ng-container>
      }
      @case (DataType.String) {
        <ng-container
          [ngTemplateOutlet]="
            controlSelector
              ? controlSelector(this, DataType.String) || stringValue
              : stringValue
          "
          [ngTemplateOutletContext]="{ $implicit: this }"
        ></ng-container>
      }
      @case (DataType.Guid) {
        <ng-container
          [ngTemplateOutlet]="
            controlSelector
              ? controlSelector(this, DataType.Guid) || stringValue
              : stringValue
          "
          [ngTemplateOutletContext]="{ $implicit: this }"
        ></ng-container>
      }
      @case (DataType.Json) {
        <ng-container
          [ngTemplateOutlet]="
            controlSelector
              ? controlSelector(this, DataType.Json) || stringValue
              : stringValue
          "
          [ngTemplateOutletContext]="{ $implicit: this }"
        ></ng-container>
      }
      @case (DataType.DateTime) {
        <ng-container
          [ngTemplateOutlet]="
            controlSelector
              ? controlSelector(this, DataType.DateTime) || dateValue
              : dateValue
          "
          [ngTemplateOutletContext]="{ $implicit: this }"
        ></ng-container>
      }
      @case (DataType.Int) {
        <ng-container
          [ngTemplateOutlet]="
            controlSelector
              ? controlSelector(this, DataType.Int) || numberValue
              : numberValue
          "
          [ngTemplateOutletContext]="{ $implicit: this }"
        ></ng-container>
      }
      @case (DataType.Long) {
        <ng-container
          [ngTemplateOutlet]="
            controlSelector
              ? controlSelector(this, DataType.Long) || numberValue
              : numberValue
          "
          [ngTemplateOutletContext]="{ $implicit: this }"
        ></ng-container>
      }
      @case (DataType.Numeric) {
        <ng-container
          [ngTemplateOutlet]="
            controlSelector
              ? controlSelector(this, DataType.Numeric) || numberValue
              : numberValue
          "
          [ngTemplateOutletContext]="{ $implicit: this }"
        ></ng-container>
      }
    }

    @if (afterControlTemplate) {
      <ng-container
        [ngTemplateOutlet]="afterControlTemplate"
        [ngTemplateOutletContext]="{ $implicit: this }"
      ></ng-container>
    }
  }

  <button
    tuiIconButton
    type="button"
    appearance="flat"
    size="s"
    icon="tuiIconClose"
    [tuiHint]="'Remove' | translate"
    (click)="remove()"
  ></button>
</div>

<ng-template #lookupValue>
  @if (lookupTableName$ | async; as lookupTableName) {
    <knk-dropdown
      [knkWithLookupOptions]="lookupTableName"
      [ngModel]="expressionValue$ | async"
      [search]="true"
      (ngModelChange)="updateLookupValue($event)"
    ></knk-dropdown>
  }
</ng-template>

<ng-template #booleanValue>
  <knk-switcher
    [ngModel]="(expressionValue$ | async) || false"
    (ngModelChange)="updateValue($event)"
  ></knk-switcher>
</ng-template>

<ng-template #stringValue>
  <knk-text-input
    [ngModel]="expressionValue$ | async"
    (ngModelChange)="updateValue($event)"
  ></knk-text-input>
</ng-template>

<ng-template #dateValue>
  <knk-dropdown
    [options]="dateValueTypeOptions"
    [ngModel]="selectedDateValueType$ | async"
    (ngModelChange)="updateValueForDynamicDate($event)"
  ></knk-dropdown>

  @if (selectedDateValueType$ | async; as dateValueType) {
    @if (dateValueType === 'static') {
      <knk-datepicker
        [ngModel]="expressionValue$ | async"
        (ngModelChange)="updateValue($event)"
      ></knk-datepicker>
    }

    @if (dateValueType === 'dynamic') {
      <knk-number-input
        [ngModel]="(dynamicConfig$ | async)?.count"
        (ngModelChange)="updateDynamicDateCount($event)"
      ></knk-number-input>

      <knk-dropdown
        [options]="intervalOptions"
        [ngModel]="(dynamicConfig$ | async)?.interval ?? null"
        (ngModelChange)="updateDynamicDateInterval($event)"
      ></knk-dropdown>
    }
  }
</ng-template>

<ng-template #numberValue>
  <knk-number-input
    [ngModel]="expressionValue$ | async"
    (ngModelChange)="updateValue($event)"
  ></knk-number-input>
</ng-template>
