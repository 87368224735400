import { Directive, inject, Input } from '@angular/core';
import { DropdownMultiselectComponent } from '@konnektu/components';
import { untilDestroyed } from '@konnektu/helpers';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
  tap
} from 'rxjs';
import { MetastoreService } from '../lib/metastore.service';
import { and, equal, from, startsWith } from '../lib/select-builder';

@Directive({
  selector: 'knk-dropdown-multiselect [knkWithLookupOptions]',
  standalone: true
})
export class WithLookupOptionsMultipleDirective {
  private readonly hostDropdownSelect = inject<
    DropdownMultiselectComponent<number>
  >(DropdownMultiselectComponent);

  private readonly metastore = inject(MetastoreService);

  private readonly currentPage$ = new BehaviorSubject(0);

  private readonly additionalFilter$ = new BehaviorSubject<Record<
    string,
    any
  > | null>(null);

  private readonly entityLabelKey$ = new BehaviorSubject<string>('Name');

  private readonly previousOptions$ = new BehaviorSubject<
    { value: number; label: string }[]
  >([]);

  entityName$ = new BehaviorSubject<string>('');

  @Input() set knkWithLookupOptions(val: string) {
    this.entityName$.next(val);
  }

  @Input() set additionalFilter(filter: Record<string, any>) {
    this.additionalFilter$.next(filter);
  }

  @Input() set entityLabelKey(key: string) {
    this.entityLabelKey$.next(key);
  }

  constructor() {
    if (!this.hostDropdownSelect) {
      throw new Error(
        'Failed to initialize directive. Host dropdown not found'
      );
    }

    this.hostDropdownSelect.scrolledToEnd
      .pipe(untilDestroyed())
      .subscribe(() =>
        this.currentPage$.next(this.currentPage$.getValue() + 1)
      );

    combineLatest([
      this.entityName$,
      this.additionalFilter$,
      this.entityLabelKey$,
      this.hostDropdownSelect.searchText$.pipe(
        distinctUntilChanged(),
        debounceTime(300),
        map((searchText) => searchText.trim()),
        tap(() => this.setDropdownLoading(true))
      )
    ])
      .pipe(
        debounceTime(250),
        tap(() => this.setDropdownLoading(true)),
        untilDestroyed(),
        shareReplay(1),
        distinctUntilChanged(),
        switchMap(([entityName, filter, entityLabelKey, searchQuery]) => {
          if (searchQuery && searchQuery.length > 2) {
            if (filter) {
              filter = and(filter, startsWith(entityLabelKey, searchQuery));
            } else {
              filter = startsWith(entityLabelKey, searchQuery);
            }
          }

          this.previousOptions$.next([]);
          this.currentPage$.next(0);
          this.setDropdownOptions(this.previousOptions$.getValue());

          return this.hostDropdownSelect.selectionChange.pipe(
            startWith([]),
            switchMap((selectedOptions) =>
              (selectedOptions.length > 0
                ? this.metastore
                    .select<{ Id: number } & Record<string, string>>(
                      from(entityName)
                        .select([
                          'Id',
                          entityLabelKey ? entityLabelKey : 'Name'
                        ])
                        .where(
                          selectedOptions.length === 1
                            ? equal('Id', selectedOptions[0].value)
                            : and(
                                ...selectedOptions.map(
                                  ({ value }: { value: number }) =>
                                    equal('Id', value)
                                )
                              )
                        )
                        .done()
                    )
                    .pipe(
                      tap((res) => {
                        if (res !== null) {
                          this.addOptionsToDropdown(
                            res.map((r) => ({
                              value: r.Id,
                              label: entityLabelKey
                                ? r[entityLabelKey]
                                : r['Name']
                            }))
                          );
                        }
                      })
                    )
                : of([])
              ).pipe(
                switchMap(() =>
                  this.currentPage$.pipe(
                    switchMap((currentPage) =>
                      this.metastore
                        .select<
                          { Name: string; Id: number } & Record<string, any>
                        >(
                          from(entityName)
                            .select([
                              'Id',
                              entityLabelKey ? entityLabelKey : 'Name'
                            ])
                            .paginate(currentPage, 50)
                            .where(filter ? filter : undefined)
                            .done()
                        )
                        .pipe(map((res) => ({ res, entityLabelKey })))
                    )
                  )
                )
              )
            )
          );
        })
      )
      .subscribe(({ res, entityLabelKey }) => {
        this.setDropdownLoading(false);
        const requestedOptions = res.map((e) => ({
          label: entityLabelKey ? e[entityLabelKey] : e.Name,
          value: e.Id
        }));
        this.previousOptions$.next([
          ...this.previousOptions$.getValue(),
          ...requestedOptions
        ]);
        this.setDropdownOptions(this.previousOptions$.getValue());
      });
  }

  private setDropdownLoading(loading: boolean) {
    this.hostDropdownSelect.loading = loading;
  }

  private setDropdownOptions(opts: { value: number; label: string }[]) {
    this.hostDropdownSelect.options = opts;
  }

  private addOptionsToDropdown(opts: { value: number; label: string }[]) {
    this.hostDropdownSelect.options = [
      ...this.hostDropdownSelect.options,
      ...opts
    ];
  }
}
