<div class="knk-exist-expression">
  <div class="knk-exist-toolbar">
    @for (part of propertyPath$ | async; track $index) {
      @if (optionsByIndex$ | async; as optionsByIndex) {
        <knk-dropdown
          [ngModel]="part"
          [search]="true"
          (ngModelChange)="updatePropertyOnIndex($event, $index)"
          [options]="optionsByIndex[$index]"
        ></knk-dropdown>
      }
    }

    @if (!(operatorOptions$ | async)?.length) {
      <knk-dropdown
        ngModel
        (ngModelChange)="pushToPropertyPathEnd($event)"
        [options]="(optionsOnEnd$ | async)!"
        [search]="true"
      ></knk-dropdown>
    }

    @if ((operatorOptions$ | async)?.length) {
      <knk-dropdown
        [ngModel]="existOperator$ | async"
        (ngModelChange)="changeOperator($event)"
        [options]="(operatorOptions$ | async)!"
      ></knk-dropdown>
    }

    @if (isInQuantity$ | async) {
      <knk-dropdown
        [options]="inQuantityOptions!"
        [ngModel]="inQuantityOperator$ | async"
        (ngModelChange)="updateInQuantityOperator($event)"
      ></knk-dropdown>
    }

    @if (inQuantityOperator$ | async; as inQuantityOperator) {
      <knk-number-input
        [ngModel]="inQuantityValueSync"
        (ngModelChange)="updateInQuantityValue($event)"
      ></knk-number-input>
    }

    <div class="knk-button-group">
      <button
        tuiIconButton
        type="button"
        appearance="flat"
        size="s"
        icon="tuiIconPlus"
        [tuiHint]="'Add condition' | translate"
        (click)="addCondition()"
      ></button>

      <button
        tuiIconButton
        type="button"
        appearance="flat"
        size="s"
        icon="tuiIconFolderPlus"
        [tuiHint]="'Add group' | translate"
        (click)="addGroup()"
      ></button>

      <button
        tuiIconButton
        type="button"
        appearance="flat"
        size="s"
        icon="tuiIconClose"
        [tuiHint]="'Remove' | translate"
        (click)="remove()"
      ></button>
    </div>
  </div>

  @if (whereBody$ | async) {
    <span class="row-tick"></span>
  }

  @if (currentNestedTable$ | async; as nestedTableName) {
    <knk-expression-builder
      class="knk-exist-body"
      [root]="false"
      [metadataProvider]="metadataProvider"
      [pathFromRoot]="whereParameterName"
      [afterControlTemplate]="afterControlTemplate"
      [beforeControlTemplate]="beforeControlTemplate"
      [controlSelector]="controlSelector"
      [expression]="(whereBody$ | async)!"
      (expressionChanged)="updateBody($event!)"
      [entityName]="nestedTableName"
      [showApply]="false"
      (removed)="updateBody(null)"
    ></knk-expression-builder>
  }
</div>
