import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import { createContextLogger, trackByIndex } from '@konnektu/helpers';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { DataType, LogicalOperator, TableFullDto } from '../../lib/models';
import { BinaryExpressionComponent } from './binary-expression.component';

type GroupingExpressionForm =
  | {
      [LogicalOperator.and]: Record<string, any>[];
    }
  | {
      [LogicalOperator.or]: Record<string, any>[];
    };

@Component({
  selector: 'knk-expression-group[entityName][expression][metadataProvider]',
  templateUrl: 'grouping-expression.component.html',
  styleUrls: ['grouping-expression.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupingExpressionComponent {
  private readonly logger = createContextLogger('GroupingExpressionComponent');

  entityName$ = new BehaviorSubject<string>('');

  @Input() set entityName(val: string) {
    this.entityName$.next(val);
  }

  expression$ = new BehaviorSubject<GroupingExpressionForm>({
    [LogicalOperator.and]: []
  });

  @Input() set expression(val: GroupingExpressionForm) {
    this.expression$.next(val);
  }

  @Input() metadataProvider!: Observable<TableFullDto[]>;

  @Input() pathFromRoot: string | undefined;

  @Input() afterControlTemplate:
    | TemplateRef<{ $implicit: BinaryExpressionComponent }>
    | undefined;

  @Input() beforeControlTemplate:
    | TemplateRef<{ $implicit: BinaryExpressionComponent }>
    | undefined;

  @Input() controlSelector:
    | ((
        comp: BinaryExpressionComponent,
        type: DataType | 'lookup'
      ) => TemplateRef<{ $implicit: BinaryExpressionComponent }> | undefined)
    | undefined;

  @Output() expressionChanged = new EventEmitter<GroupingExpressionForm>();

  @Output() removed = new EventEmitter<void>();

  expressionBody$ = this.expression$.pipe(
    map((expression) =>
      LogicalOperator.and in expression
        ? expression[LogicalOperator.and]
        : expression[LogicalOperator.or]
    )
  );

  get expressionBodySync() {
    const expr = this.expression$.getValue();
    return LogicalOperator.and in expr
      ? expr[LogicalOperator.and]
      : expr[LogicalOperator.or];
  }

  expressionOperator$ = this.expression$.pipe(
    map((expression) => (LogicalOperator.and in expression ? 'AND' : 'OR'))
  );

  get expressionOperatorSync() {
    return LogicalOperator.and in this.expression$.getValue() ? 'AND' : 'OR';
  }

  trackByIndex = trackByIndex;

  parameterNameForIndex(index: number) {
    return `${this.pathFromRoot ? `${this.pathFromRoot}:` : ''}${
      LogicalOperator.and in this.expression$.getValue()
        ? LogicalOperator.and
        : LogicalOperator.or
    }:${index}`;
  }

  toggleOperator() {
    const expr = this.expression$.getValue();
    if (LogicalOperator.and in expr) {
      this.expressionChanged.emit({
        [LogicalOperator.or]: expr[LogicalOperator.and]
      });
    } else {
      this.expressionChanged.emit({
        [LogicalOperator.and]: expr[LogicalOperator.or]
      });
    }
  }

  addCondition() {
    const expr = this.expression$.getValue();
    if (LogicalOperator.and in expr) {
      this.expressionChanged.emit({
        [LogicalOperator.and]: [...expr[LogicalOperator.and], {}]
      });
    } else {
      this.expressionChanged.emit({
        [LogicalOperator.or]: [...expr[LogicalOperator.or], {}]
      });
    }
  }

  addGroup() {
    const expr = this.expression$.getValue();
    if (LogicalOperator.and in expr) {
      this.expressionChanged.emit({
        [LogicalOperator.and]: [
          ...expr[LogicalOperator.and],
          { [LogicalOperator.and]: [] }
        ]
      });
    } else {
      this.expressionChanged.emit({
        [LogicalOperator.or]: [
          ...expr[LogicalOperator.or],
          { [LogicalOperator.or]: [] }
        ]
      });
    }
  }

  innerUpdate(expression: Record<string, any>, index: number) {
    this.logger.debug(`#innerUpdate(${JSON.stringify(expression)}, ${index})`);
    const expr = this.expression$.getValue();
    if (LogicalOperator.and in expr) {
      const newBody = [...expr[LogicalOperator.and]];
      newBody.splice(index, 1, expression);
      this.expressionChanged.emit({
        [LogicalOperator.and]: newBody
      });
    } else {
      const newBody = [...expr[LogicalOperator.or]];
      newBody.splice(index, 1, expression);
      this.expressionChanged.emit({
        [LogicalOperator.or]: newBody
      });
    }
  }

  removeInner(index: number) {
    this.logger.debug(`#removeInner(${index})`);
    const expr = this.expression$.getValue();
    if (LogicalOperator.and in expr) {
      const newBody = [...expr[LogicalOperator.and]];
      newBody.splice(index, 1);
      if (newBody.length === 1) {
        this.expressionChanged.emit(newBody[0] as GroupingExpressionForm);
      } else {
        this.expressionChanged.emit({
          [LogicalOperator.and]: newBody
        });
      }
    } else {
      const newBody = [...expr[LogicalOperator.or]];
      newBody.splice(index, 1);
      if (newBody.length === 1) {
        this.expressionChanged.emit(newBody[0] as GroupingExpressionForm);
      } else {
        this.expressionChanged.emit({
          [LogicalOperator.or]: newBody
        });
      }
    }
  }

  remove() {
    this.removed.emit();
  }
}
