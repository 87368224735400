import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import { createContextLogger } from '@konnektu/helpers';
import { BehaviorSubject, Observable, concatMap, map, of } from 'rxjs';
import { DataType, LogicalOperator, TableFullDto } from '../../lib/models';
import {
  isBinaryExpression,
  isExistExpression,
  isGroupingExpression,
  pushToGroupingExpression
} from '../../lib/where-helpers';
import { BinaryExpressionComponent } from './binary-expression.component';

@Component({
  selector: 'knk-expression-builder[expression][entityName][metadataProvider]',
  styleUrls: ['expression-builder.component.scss'],
  templateUrl: 'expression-builder.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpressionBuilderComponent {
  private readonly logger = createContextLogger('ExpressionBuilderComponent');

  expression$ = new BehaviorSubject<Record<string, any> | null>(null);

  @Input() set expression(val: Record<string, any> | null) {
    this.expression$.next(val);
  }

  entityName$ = new BehaviorSubject<string>('');

  @Input() set entityName(val: string) {
    this.entityName$.next(val);
  }

  @Input() metadataProvider!: Observable<TableFullDto[]>;

  @Input() root = true;

  @Input() pathFromRoot: string | undefined;

  @Input() showApply = true;

  @Input() isShowReset = false;

  @Input() showSave = false;

  @Input() saveHint = '';

  @Input() beforeControlTemplate:
    | TemplateRef<{ $implicit: BinaryExpressionComponent }>
    | undefined;

  @Input() afterControlTemplate:
    | TemplateRef<{ $implicit: BinaryExpressionComponent }>
    | undefined;

  @Input() controlSelector:
    | ((
        comp: BinaryExpressionComponent,
        type: DataType | 'lookup'
      ) => TemplateRef<{ $implicit: BinaryExpressionComponent }> | undefined)
    | undefined;

  @Output() expressionChanged = new EventEmitter<Record<string, any> | null>();

  @Output() removed = new EventEmitter<void>();

  @Output() resetClicked = new EventEmitter();

  @Output() saveClick = new EventEmitter<void>();

  @Output() applied = new EventEmitter<Record<string, any> | null>();

  loading$ = of(true).pipe(
    concatMap(() => this.metadataProvider),
    map(() => false)
  );

  isBinaryExpression$ = this.expression$.pipe(
    map((expression) => (expression ? isBinaryExpression(expression) : false))
  );

  isGroupingExpression$ = this.expression$.pipe(
    map((expression) => (expression ? isGroupingExpression(expression) : false))
  );

  isExistExpression$ = this.expression$.pipe(
    map((expression) => (expression ? isExistExpression(expression) : false))
  );

  updateExpression(expr: Record<string, any>) {
    this.expression$.next(expr);
    this.expressionChanged.emit(expr);
  }

  addCondition() {
    const current = this.expression$.getValue();
    if (current !== null) {
      if (isGroupingExpression(current)) {
        const next = pushToGroupingExpression(current, {});
        this.logger.debug(
          `Add condition to grouping expression\nCurrent:\n${JSON.stringify(
            current
          )}\nNext:${JSON.stringify(next)}`
        );
        this.expression$.next(next);
        this.expressionChanged.emit(this.expression$.getValue());
        return;
      }
      if (isBinaryExpression(current)) {
        const next = {
          [LogicalOperator.and]: [current, {}]
        };
        this.logger.debug(
          `Add condition to binary expression\nCurrent:\n${JSON.stringify(
            current
          )}\nNext:${JSON.stringify(next)}`
        );
        this.expression$.next({
          [LogicalOperator.and]: [current, {}]
        });
        this.expressionChanged.emit(this.expression$.getValue());
        return;
      }
      if (isExistExpression(current)) {
        const next = {
          [LogicalOperator.and]: [current, {}]
        };
        this.logger.debug(
          `Add condition to exist expression\nCurrent:\n${JSON.stringify(
            current
          )}\nResult:\n${JSON.stringify(next)}`
        );
        this.expression$.next(next);
        this.expressionChanged.emit(this.expression$.getValue());
        return;
      }
    } else {
      this.logger.debug('Add condition on empty expression');
      this.expression$.next({});
    }
    this.expressionChanged.emit(
      this.expression$.getValue() as Record<string, any>
    );
  }

  addGroup() {
    const current = this.expression$.getValue();
    if (current !== null) {
      if (isGroupingExpression(current)) {
        this.expression$.next(
          pushToGroupingExpression(current, { [LogicalOperator.and]: [] })
        );
        this.expressionChanged.emit(this.expression$.getValue());
        return;
      }
      if (isBinaryExpression(current)) {
        this.expression$.next({
          [LogicalOperator.and]: [current, { [LogicalOperator.and]: [] }]
        });
        this.expressionChanged.emit(this.expression$.getValue());
        return;
      }
      if (isExistExpression(current)) {
        this.expression$.next({
          [LogicalOperator.and]: [current, { [LogicalOperator.and]: [] }]
        });
        this.expressionChanged.emit(this.expression$.getValue());
        return;
      }
    } else {
      this.expression$.next({
        [LogicalOperator.and]: []
      });
    }

    this.expressionChanged.emit(
      this.expression$.getValue() as Record<string, any>
    );
  }

  apply() {
    this.applied.emit(this.expression$.getValue());
  }

  remove() {
    this.expression$.next(null);
    this.removed.emit();
  }

  resetFilter() {
    this.resetClicked.emit();
  }

  onSaveClick() {
    this.saveClick.emit();
  }
}
