import { DataType, LogicalOperator, MetastoreOperator } from './models';

export const DYNAMIC_DATE_REGEX =
  /\$(?<interval>seconds|minutes|hours|days|months|years):(?<count>\d*)/gm;

export const defaultOperators: MetastoreOperator[] = [
  MetastoreOperator.is,
  MetastoreOperator.isNot
];

export const numberOperators: MetastoreOperator[] = [
  MetastoreOperator.eq,
  MetastoreOperator.ne,
  MetastoreOperator.gt,
  MetastoreOperator.gte,
  MetastoreOperator.lt,
  MetastoreOperator.lte
];

export const dataTypeToOperator: Record<DataType, MetastoreOperator[]> = {
  [DataType.Int]: [...defaultOperators, ...numberOperators],
  [DataType.Long]: [...defaultOperators, ...numberOperators],
  [DataType.String]: [
    ...defaultOperators,
    MetastoreOperator.eq,
    MetastoreOperator.ne,
    MetastoreOperator.icContains,
    MetastoreOperator.icNotContains,
    MetastoreOperator.startsWith,
    MetastoreOperator.notStartsWith
  ],
  [DataType.DateTime]: [
    ...defaultOperators,
    MetastoreOperator.eq,
    MetastoreOperator.gt,
    MetastoreOperator.gte,
    MetastoreOperator.lt,
    MetastoreOperator.lte
  ],
  [DataType.Guid]: [
    ...defaultOperators,
    MetastoreOperator.eq,
    MetastoreOperator.ne
  ],
  [DataType.Bool]: [
    ...defaultOperators,
    MetastoreOperator.eq,
    MetastoreOperator.ne
  ],
  [DataType.Json]: [...defaultOperators],
  [DataType.Numeric]: [...defaultOperators, ...numberOperators],
  [DataType.Double]: [...defaultOperators, ...numberOperators],
  [DataType.Array]: defaultOperators,
  [DataType.Enum]: defaultOperators
};

export const IN_QUANTITY = '<in quantity>';

export const operatorToString: Record<MetastoreOperator, string> = {
  [MetastoreOperator.eq]: 'equal',
  [MetastoreOperator.exist]: 'exist',
  [MetastoreOperator.gt]: 'greater',
  [MetastoreOperator.gte]: 'greater or equal',
  [MetastoreOperator.is]: 'not filled in',
  [MetastoreOperator.isNot]: 'filled in',
  [MetastoreOperator.lt]: 'less',
  [MetastoreOperator.icContains]: 'contains',
  [MetastoreOperator.icNotContains]: 'not contains',
  [MetastoreOperator.lte]: 'less or equal',
  [MetastoreOperator.ne]: 'not equal',
  [MetastoreOperator.notExist]: 'not exist',
  [MetastoreOperator.startsWith]: 'starts with',
  [MetastoreOperator.notStartsWith]: 'not starts with'
};

export const logicalOperatorToString: Record<LogicalOperator, string> = {
  [LogicalOperator.and]: 'And',
  [LogicalOperator.or]: 'Or'
};

export const existOptions: {
  label: string;
  value: MetastoreOperator | typeof IN_QUANTITY;
}[] = [
  {
    value: MetastoreOperator.exist,
    label: operatorToString[MetastoreOperator.exist]
  },
  {
    value: MetastoreOperator.notExist,
    label: operatorToString[MetastoreOperator.notExist]
  },
  {
    value: IN_QUANTITY,
    label: 'in quantity'
  }
];

export const lookupTableNames: string[] = [
  'BonusWallet',
  'Campaign',
  'CampaignSource',
  'Communication',
  'CommunicationChannel',
  'CommunicationType',
  'CommunicationActivityType',
  'CommunicationIdentifierStatus',
  'CouponGenerator',
  'CouponType',
  'CustomEvent',
  'Gender',
  'IdentifierType',
  'Method',
  'Question',
  'Questionnaire',
  'TicketGenerator',
  'Unsubscribe',
  'UserStatus',
  'UserType'
];
