import { DYNAMIC_DATE_REGEX } from './constants';
import { MetastoreOperator, Parameters } from './models';

/**
 * will transform object with parameters
 * to object with actual values
 * example:
 * ```json
 * {
 *  "object": {
 *    "Id": {"$eq": "@123"}
 *  },
 *  "parameters": {
 *    "@123": 1
 *  }
 * }
 * ```
 *
 * will be transformed to:
 * ```json
 * {
 *  "Id": {"$eq": 1}
 * }
 * ```
 */
export const injectObjectParameters = (
  object: Record<string, unknown>,
  parameters: Parameters
) => {
  if (!object) {
    return {};
  }
  const result: Record<string, any> = {};
  Object.keys(object).forEach((key) => {
    if (
      [MetastoreOperator.exist, MetastoreOperator.notExist].includes(
        key as MetastoreOperator
      ) &&
      typeof object[key] === 'boolean'
    ) {
      result[key] = object[key];
      return;
    }
    if (typeof object[key] === 'string') {
      if ([...(object[key] as string).matchAll(DYNAMIC_DATE_REGEX)].length) {
        result[key] = object[key];
        return;
      }
      result[key] = parameters[object[key] as string];
      return;
    }
    if (Array.isArray(object[key])) {
      result[key] = [];
      (object[key] as Record<string, any>[]).forEach((element) =>
        result[key].push(injectObjectParameters(element, parameters))
      );
      return;
    }
    if (object[key] === null) {
      result[key] = null;
      return;
    }
    if (typeof object[key] === 'object') {
      result[key] = injectObjectParameters(
        object[key] as Record<string, any>,
        parameters
      );
      return;
    }
  });

  return result;
};
