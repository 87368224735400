import { DYNAMIC_DATE_REGEX } from './constants';
import { MetastoreOperator, Parameters } from './models';

const _extractObjectParamsInternal = (
  original: Record<string, any>,
  pathFromParent?: string[]
) => {
  let params: Parameters = {};
  const resultObj: Record<string, any> = {};
  if (!original) {
    return { obj: resultObj, parameters: params };
  }
  for (const key of Object.keys(original)) {
    if (
      [MetastoreOperator.is, MetastoreOperator.isNot].includes(
        key as MetastoreOperator
      ) &&
      original[key] === null
    ) {
      resultObj[key] = original[key];
      continue;
    }

    if (
      [MetastoreOperator.exist, MetastoreOperator.notExist].includes(
        key as MetastoreOperator
      ) &&
      typeof original[key] === 'boolean'
    ) {
      resultObj[key] = original[key];
      continue;
    }
    if (
      ['string', 'number', 'boolean'].includes(typeof original[key]) ||
      original[key] instanceof Date
    ) {
      if (
        typeof original[key] === 'string' &&
        [...(original[key] as string).matchAll(DYNAMIC_DATE_REGEX)].length
      ) {
        resultObj[key] = original[key];
        continue;
      }
      const paramKey = pathFromParent
        ? `@${pathFromParent.join(':')}:${key}`
        : `@${key}`;
      resultObj[key] = paramKey;
      // убираем Z с конца даты
      if (original[key] instanceof Date) {
        const dateString = (original[key] as Date).toISOString();
        if (dateString.endsWith('Z')) {
          params[paramKey] = dateString.slice(0, -1);
        }
      } else {
        params[paramKey] = original[key];
      }
    } else if (Array.isArray(original[key])) {
      resultObj[key] = [];
      original[key].forEach((value: Record<string, any>, index: number) => {
        const { obj, parameters } = _extractObjectParamsInternal(
          value,
          pathFromParent
            ? [...pathFromParent, key, index.toString()]
            : [key, index.toString()]
        );
        resultObj[key].push(obj);
        params = { ...params, ...parameters };
      });
    } else if (!original[key]) {
    } else {
      const { parameters: nestedParameters, obj: nestedObj } =
        _extractObjectParamsInternal(
          original[key],
          pathFromParent ? [...pathFromParent, key] : [key]
        );
      resultObj[key] = nestedObj;
      params = {
        ...params,
        ...nestedParameters
      };
    }
  }
  return { obj: resultObj, parameters: params };
};

export const extractObjectParams = (original: Record<string, any>) =>
  _extractObjectParamsInternal(original);

export const extractArrayParams = (from: any[]) => {
  const extracted = from.map((f, index) =>
    _extractObjectParamsInternal(f, [index.toString()])
  );
  return {
    extracted: extracted.map((e) => e.obj),
    parameters: extracted.reduce(
      (prev, curr) => ({ ...prev, ...curr.parameters }),
      {}
    )
  };
};
