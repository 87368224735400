import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  ButtonModule,
  DatepickerModule,
  DropdownMenuModule,
  DropdownModule,
  NumberInputModule,
  SwitcherModule,
  TextInputModule
} from '@konnektu/components';
import {
  iconAddBox,
  iconClose,
  iconLibraryAdd,
  iconLoading,
  iconRefresh,
  provideIcons
} from '@konnektu/icons';
import { TranslateModule } from '@ngx-translate/core';
import {
  TuiButtonModule,
  TuiGroupModule,
  TuiHintModule,
  TuiLoaderModule,
  TuiSvgModule
} from '@taiga-ui/core';
import { WithLookupOptionsDirective } from '../directives';
import { BinaryExpressionComponent } from './expression-builder/binary-expression.component';
import { ExistExpressionComponent } from './expression-builder/exist-expression.component';
import { ExpressionBuilderComponent } from './expression-builder/expression-builder.component';
import { GroupingExpressionComponent } from './expression-builder/grouping-expression.component';
import { FieldSelectorComponent } from './field-selector/field-selector.component';
import { SingleFieldComponent } from './field-selector/single-field.component';

@NgModule({
  declarations: [
    GroupingExpressionComponent,
    BinaryExpressionComponent,
    ExistExpressionComponent,
    ExpressionBuilderComponent,
    FieldSelectorComponent,
    SingleFieldComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    DropdownModule,
    NumberInputModule,
    WithLookupOptionsDirective,
    TextInputModule,
    SwitcherModule,
    DatepickerModule,
    DropdownMenuModule,
    MatTooltipModule,
    TuiButtonModule,
    TuiGroupModule,
    TuiHintModule,
    TranslateModule,
    TuiSvgModule,
    TuiLoaderModule
  ],
  exports: [
    GroupingExpressionComponent,
    BinaryExpressionComponent,
    ExistExpressionComponent,
    ExpressionBuilderComponent,
    FieldSelectorComponent
  ],
  providers: [
    provideIcons([
      iconAddBox,
      iconClose,
      iconLibraryAdd,
      iconLoading,
      iconRefresh
    ])
  ]
})
export class MetastoreComponentsModule {}
