<ul class="knk-group-expression">
  <li class="group-toolbar" #groupToolbarRow>
    <span class="row-tick"></span>
    <div class="knk-button-group rounded-md">
      <button
        tuiButton
        type="button"
        appearance="flat"
        size="s"
        class="tui-space_right-1"
        (click)="toggleOperator()"
      >
        {{ (expressionOperator$ | async)! | translate }}
      </button>

      <button
        tuiIconButton
        type="button"
        appearance="flat"
        size="s"
        icon="tuiIconPlus"
        [tuiHint]="'Add condition' | translate"
        (click)="addCondition()"
      ></button>

      <button
        tuiIconButton
        type="button"
        appearance="flat"
        size="s"
        icon="tuiIconFolderPlus"
        [tuiHint]="'Add group' | translate"
        (click)="addGroup()"
      ></button>

      <button
        tuiIconButton
        type="button"
        appearance="flat"
        size="s"
        icon="tuiIconClose"
        [tuiHint]="'Remove' | translate"
        (click)="remove()"
      ></button>
    </div>
  </li>
  @for (
      inner of expressionBody$ | async;
    track trackByIndex;
    let index = $index
    ) {
    <li class="group-condition" #groupCondition>
      <span class="row-tick"></span>
      <knk-expression-builder
        [metadataProvider]="metadataProvider"
        [entityName]="(entityName$ | async)!"
        [pathFromRoot]="parameterNameForIndex(index)"
        (expressionChanged)="innerUpdate($event!, index)"
        [root]="false"
        [beforeControlTemplate]="beforeControlTemplate"
        [afterControlTemplate]="afterControlTemplate"
        [controlSelector]="controlSelector"
        [expression]="$any(inner)"
        (removed)="removeInner(index)"
        [showApply]="false"
      ></knk-expression-builder>
    </li>
  }
</ul>
