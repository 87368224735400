import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import slice from 'lodash-es/slice';
import { BehaviorSubject, Observable, forkJoin, map, switchMap } from 'rxjs';
import { getTableMetaByPath } from '../../lib/metadata-utils';
import { TableFullDto } from '../../lib/models';
import {
  extractPropertyPathFromSelect,
  setPropertyPathOnSelect
} from '../../lib/select-helpers';

@Component({
  selector: 'knk-single-field[tableName][metadataProvider]',
  templateUrl: 'single-field.component.html',
  styleUrls: ['single-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleFieldComponent {
  protected _tableName$ = new BehaviorSubject<string>('');

  @Input() set tableName(tableName: string) {
    this._tableName$.next(tableName);
  }

  protected _field$ = new BehaviorSubject<string | Record<string, unknown>>('');

  @Input() set field(field: string | Record<string, any>) {
    this._field$.next(field);
  }

  @Output() fieldChanged = new EventEmitter<string | Record<string, unknown>>();

  @Input() canDelete = true;

  @Input() isDisabled = false;

  @Input() metadataProvider!: Observable<TableFullDto[]>;

  @Output() removed = new EventEmitter<void>();

  readonly optionsByIndex$ = this._field$.pipe(
    map((field) => {
      const path = extractPropertyPathFromSelect(field);
      return path.map((_, index) => slice(path, 0, index));
    }),
    switchMap((paths) =>
      forkJoin(
        paths.map((path) =>
          getTableMetaByPath(
            this.metadataProvider,
            this._tableName$.getValue(),
            path
          )
        )
      )
    ),
    map((optionsByIndex) =>
      optionsByIndex.map((optionByIndex) => {
        if (optionByIndex === null) {
          return [];
        }
        if (typeof optionByIndex === 'number') {
          return [];
        } else {
          return [
            ...optionByIndex.meta.columns.map((c) => ({
              value: c.name,
              label: c.name
            })),
            ...(optionByIndex.meta.collections?.map((r) => ({
              value: r.name,
              label: r.name
            })) ?? []),
            ...(optionByIndex.meta.properties?.map((r) => ({
              value: r.name,
              label: r.name
            })) ?? [])
          ];
        }
      })
    )
  );

  readonly fieldPath$ = this._field$.pipe(
    map((field) => extractPropertyPathFromSelect(field))
  );

  readonly optionsOnEnd$ = this.fieldPath$.pipe(
    switchMap((path) =>
      getTableMetaByPath(
        this.metadataProvider,
        this._tableName$.getValue(),
        path
      )
    ),
    map((tableOrProperty) => {
      if (tableOrProperty === null) {
        return undefined;
      }
      if (typeof tableOrProperty === 'number') {
        return undefined;
      } else {
        return [
          ...tableOrProperty.meta.columns.map((c) => ({
            value: c.name,
            label: c.name
          })),
          ...(tableOrProperty.meta.collections?.map((r) => ({
            value: r.name,
            label: r.name
          })) ?? []),
          ...(tableOrProperty.meta.properties?.map((r) => ({
            value: r.name,
            label: r.name
          })) ?? [])
        ];
      }
    })
  );

  changedFieldAtIndex(part: string, index: number): void {
    const field = this._field$.getValue();
    const oldPath = extractPropertyPathFromSelect(field);
    const newPath = [...oldPath];
    newPath.splice(index, 1, part);
    this._field$.next(setPropertyPathOnSelect(field, newPath.join('.')));
    this.fieldChanged.emit(this._field$.getValue());
  }

  addPropertyFromNavigation(part: string): void {
    this._field$.next(
      setPropertyPathOnSelect(
        this._field$.getValue(),
        [...extractPropertyPathFromSelect(this._field$.getValue()), part].join(
          '.'
        )
      )
    );
    this.fieldChanged.emit(this._field$.getValue());
  }

  remove(): void {
    this.removed.emit();
  }
}
