import { LogicalOperator, MetastoreOperator } from './models';

export const extractPropertyPath = (obj: Record<string, any>) =>
  Object.keys(obj)[0]?.split('.') ?? [];

export const extractBinaryValue = (obj: Record<string, any>) =>
  Object.values(obj).length
    ? Object.values(Object.values(obj)[0])
      ? Object.values(Object.values(obj)[0])[0]
      : undefined
    : undefined;

export const extractOperator = (obj: Record<string, any>) => {
  if (Object.keys(obj).length === 0) {
    return undefined;
  }
  if (
    [LogicalOperator.and, LogicalOperator.or].includes(
      Object.keys(obj)[0] as LogicalOperator
    )
  ) {
    return Object.keys(obj)[0] as LogicalOperator;
  }
  return Object.keys(Object.values(obj)[0])[0] as MetastoreOperator;
};

export const firstKey = (obj: Record<string, any>) => Object.keys(obj)[0];

export const firstValue = (obj: Record<string, unknown>) =>
  Object.values(obj)[0];

export const isExistExpression = (obj: Record<string, any>) => {
  if (
    obj &&
    Object.values(obj).length &&
    Object.keys(Object.values(obj)).length
  ) {
    return [MetastoreOperator.exist, MetastoreOperator.notExist].includes(
      Object.keys(Object.values(obj)[0])[0] as MetastoreOperator
    );
  }
  return false;
};

export const isBinaryExpression = (obj: Record<string, any>) =>
  !isExistExpression(obj) && !isGroupingExpression(obj);

export const isGroupingExpression = (obj: Record<string, any>) =>
  obj
    ? [LogicalOperator.and, LogicalOperator.or].includes(
        Object.keys(obj)[0] as LogicalOperator
      )
    : false;

export const extractHavingFromExist = (obj: Record<string, any>) =>
  (Object.values(Object.values(obj)[0] as Record<string, any>)[0]
    ?.$having as Record<string, any>) ?? undefined;

export const extractWhereFromExist = (obj: Record<string, any>) =>
  (Object.values(Object.values(obj)[0] as Record<string, any>)[0]
    ?.$where as Record<string, any>) ?? undefined;

export const pushToGroupingExpression = (
  expression: Record<string, any>,
  condition: Record<string, any>
) => {
  if (LogicalOperator.and in expression) {
    return {
      [LogicalOperator.and]: [...expression[LogicalOperator.and], condition]
    };
  }
  if (LogicalOperator.or in expression) {
    return {
      [LogicalOperator.or]: [...expression[LogicalOperator.or], condition]
    };
  }
  return expression;
};
