import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TableFullDto } from '../../lib/models';

@Component({
  selector: 'knk-field-selector[entityName][metadataProvider]',
  templateUrl: 'field-selector.component.html',
  styleUrls: ['field-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldSelectorComponent {
  protected _entityName$ = new BehaviorSubject<string>('');

  @Input() set entityName(val: string) {
    this._entityName$.next(val);
    this._fields$.next([]);
  }

  protected _fields$ = new BehaviorSubject<(string | Record<string, any>)[]>(
    []
  );

  @Input() set fields(fields: (string | Record<string, any>)[]) {
    this._fields$.next(fields);
  }

  @Input() metadataProvider!: Observable<TableFullDto[]>;

  @Input() useSelectIndexes = false;

  @Input() indexedFields: string[] = [];

  @Input() isIndexUnique = false;

  @Input() isEntityNameAndFieldSelectorBlocked = false;

  @Output() isIndexUniqueChange = new EventEmitter<boolean>();

  @Output() indexedFieldsChanged = new EventEmitter<string[]>();

  @Output() fieldsChanged = new EventEmitter<
    (string | Record<string, any>)[]
  >();

  addNewField(): void {
    const fields = this._fields$.getValue();
    this._fields$.next(fields.length === 0 ? [''] : [...fields, '']);
    this.fieldsChanged.emit(this._fields$.getValue());
  }

  updatedFieldOnIndex(
    index: number,
    field: string | Record<string, any>
  ): void {
    if (this._fields$.getValue().length === 1) {
      this.fieldIndexChange(true, String(field));
    } else {
      this.fieldIndexChange(false, String(field));
    }
    const newProps = [...this._fields$.getValue()];
    newProps[index] = field;
    this._fields$.next(newProps);
    this.fieldsChanged.emit(this._fields$.getValue());
  }

  removeField(index: number): void {
    const newProps = [...this._fields$.getValue()];
    newProps.splice(index, 1);
    this._fields$.next(newProps);
    this.fieldsChanged.emit(this._fields$.getValue());
  }

  fieldIndexChange(indexed: boolean, field: string) {
    if (indexed && !this.indexedFields.includes(field)) {
      this.indexedFields.push(field);
    }
    if (!indexed && this.indexedFields.includes(field)) {
      const found = this.indexedFields.indexOf(field);
      this.indexedFields.splice(found, 1);
    }
    this.indexedFieldsChanged.emit(this.indexedFields);
  }
}
