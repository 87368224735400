<div class="knk-field-selector">
  @for (selected of _fields$ | async; track $index) {
    <div class="knk-field-selector__field">
      <knk-single-field
        [tableName]="(_entityName$ | async)!"
        [field]="selected"
        [isDisabled]="isEntityNameAndFieldSelectorBlocked"
        (fieldChanged)="updatedFieldOnIndex($index, $event)"
        (removed)="removeField($index)"
        [metadataProvider]="metadataProvider"
      ></knk-single-field>

      @if (useSelectIndexes && selected) {
        <knk-switcher
          [disabled]="isEntityNameAndFieldSelectorBlocked"
          [ngModel]="indexedFields.indexOf($any(selected)) !== -1"
          (ngModelChange)="fieldIndexChange($event, $any(selected))"
          [label]="'Index' | translate"
        ></knk-switcher>
      }
    </div>
  }

  <div class="knk-field-selector__actions">
    @if (!isEntityNameAndFieldSelectorBlocked) {
      <button
        tuiButton
        type="button"
        appearance="primary"
        size="m"
        (click)="addNewField()"
      >
        {{ 'Add field' | translate }}
      </button>
    }
    @if (useSelectIndexes) {
      <knk-switcher
        [(ngModel)]="isIndexUnique"
        [disabled]="isEntityNameAndFieldSelectorBlocked"
        (ngModelChange)="isIndexUniqueChange.emit($event)"
        [label]="'Unique index' | translate"
      ></knk-switcher>
    }
  </div>
</div>
