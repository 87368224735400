import { Directive, inject, Input } from '@angular/core';
import { DropdownComponent, DropdownOption } from '@konnektu/components';
import { untilDestroyed } from '@konnektu/helpers';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  of,
  filter as rxjsFilter,
  shareReplay,
  switchMap,
  tap
} from 'rxjs';
import { MetastoreService } from '../lib/metastore.service';
import { and, equal, from, notEqual, startsWith } from '../lib/select-builder';

@Directive({
  selector: 'knk-dropdown [knkWithLookupOptions]',
  standalone: true
})
export class WithLookupOptionsDirective<
  TVal extends string | number | boolean | null
> {
  private readonly hostDropdownSelect =
    inject<DropdownComponent<TVal>>(DropdownComponent);

  private readonly metastore = inject(MetastoreService);

  entityName$ = new BehaviorSubject<string>('');

  additionalFilter$ = new BehaviorSubject<Record<string, any> | null>(null);

  additionalFields$ = new BehaviorSubject<string[]>([]);

  entityLabelKey$ = new BehaviorSubject<string | ((val: any) => string)>(
    'Name'
  );

  entityValueKey$ = new BehaviorSubject<string>('Id');

  @Input() set knkWithLookupOptions(val: string) {
    this.entityName$.next(val);
  }

  @Input() set additionalFilter(filter: Record<string, any>) {
    this.additionalFilter$.next(filter);
  }

  @Input() set entityValueKey(key: string) {
    this.entityValueKey$.next(key);
  }

  @Input() set entityLabelKey(key: string | ((val: any) => string)) {
    this.entityLabelKey$.next(key);
  }

  @Input() searchOnField?: string;

  @Input() set additionalFields(fields: string[]) {
    this.additionalFields$.next(fields);
  }

  protected currentPage$ = new BehaviorSubject(0);

  protected currentSelectedOption$ =
    new BehaviorSubject<DropdownOption<number> | null>(null);

  constructor() {
    if (!this.hostDropdownSelect) {
      throw new Error(
        'Failed to initialize directive. Host dropdown not found'
      );
    }

    this.hostDropdownSelect.useDefaultFilter = false;

    this.hostDropdownSelect.scrolledToEnd
      .pipe(untilDestroyed())
      .subscribe(() =>
        this.currentPage$.next(this.currentPage$.getValue() + 1)
      );

    combineLatest([
      this.entityName$,
      this.additionalFilter$,
      this.entityLabelKey$,
      this.additionalFields$,
      this.hostDropdownSelect.searchText$.pipe(
        distinctUntilChanged(),
        debounceTime(300),
        map((searchText) => searchText.trim())
      ),
      this.hostDropdownSelect.rawSelectedValue$
    ])
      .pipe(
        rxjsFilter(([entityName]) => entityName !== ''),
        tap(() => this.setDropdownLoading(true)),
        untilDestroyed(),
        shareReplay(1),
        distinctUntilChanged(),
        switchMap(
          ([
            entityName,
            filter,
            labelKey,
            additionalFields,
            searchText,
            selectedValue
          ]) => {
            const fieldToRequestAdditionaly = [...additionalFields];
            const valueKey = this.entityValueKey$.getValue();
            if (typeof labelKey === 'string') {
              fieldToRequestAdditionaly.push(labelKey);
            }
            const query = from(entityName).select([
              valueKey,
              ...fieldToRequestAdditionaly
            ]);

            let resultFilter = filter;

            if (searchText && searchText.length > 2) {
              if (resultFilter) {
                if (typeof labelKey === 'string') {
                  resultFilter = and(
                    resultFilter,
                    startsWith(labelKey, searchText)
                  );
                } else if (this.searchOnField) {
                  resultFilter = and(
                    resultFilter,
                    startsWith(this.searchOnField, searchText)
                  );
                }
              } else {
                if (typeof labelKey === 'string') {
                  resultFilter = startsWith(labelKey, searchText);
                } else if (this.searchOnField) {
                  resultFilter = startsWith(this.searchOnField, searchText);
                }
              }
            }

            if (selectedValue) {
              if (resultFilter) {
                resultFilter = and(
                  resultFilter,
                  notEqual(valueKey, selectedValue)
                );
              } else {
                resultFilter = notEqual(valueKey, selectedValue);
              }
            }

            if (resultFilter) {
              query.where(resultFilter);
            }

            this.setDropdownOptions([]);

            return this.hostDropdownSelect.rawSelectedValue$.pipe(
              switchMap((v) =>
                (v
                  ? this.metastore.find<Record<string, TVal>>(
                      from(entityName)
                        .select([valueKey, ...fieldToRequestAdditionaly])
                        .where(equal(valueKey, v as number))
                        .done()
                    )
                  : of(null)
                ).pipe(
                  tap((res) => {
                    if (res !== null) {
                      this.addOptionsToDropdown([
                        {
                          value: res[valueKey],
                          label: (labelKey
                            ? typeof labelKey === 'string'
                              ? res[labelKey]
                              : labelKey(res)
                            : res['Name']) as string,
                          additionalFields: fieldToRequestAdditionaly.reduce(
                            (prev, field) => ({ ...prev, field: res[field] }),
                            {}
                          )
                        }
                      ]);
                    }
                  }),
                  switchMap(() =>
                    this.currentPage$.pipe(
                      switchMap((page) =>
                        this.metastore
                          .select<
                            { Name: string; Id: number } & Record<string, any>
                          >(query.paginate(page, 50).done())
                          .pipe(
                            map((res) => ({
                              res,
                              labelKey,
                              fieldToRequestAdditionaly
                            }))
                          )
                      )
                    )
                  )
                )
              )
            );
          }
        )
      )
      .subscribe(({ res, labelKey, fieldToRequestAdditionaly }) => {
        const valueKey = this.entityValueKey$.getValue();
        const opts = res.map((e) => ({
          label: labelKey
            ? typeof labelKey === 'string'
              ? e[labelKey]
              : labelKey(e)
            : e['Name'],
          value: e[valueKey] as TVal,
          additionalFields: fieldToRequestAdditionaly.reduce(
            (prev, field) => ({ ...prev, [field]: e[field] }),
            {}
          )
        }));
        this.addOptionsToDropdown(opts);
        this.setDropdownLoading(false);
      });
  }

  private setDropdownLoading(loading: boolean) {
    this.hostDropdownSelect.loading = loading;
    // this.hostDropdownSelect.disabled = loading;
  }

  private addOptionsToDropdown(opts: DropdownOption<TVal>[]) {
    this.hostDropdownSelect.options = [
      ...this.hostDropdownSelect.options,
      ...opts
    ];
  }

  private setDropdownOptions(opts: DropdownOption<TVal>[]) {
    this.hostDropdownSelect.options = opts;
  }
}
