import { Injectable } from '@angular/core';

export const LOOKUP_PROVIDERS = [
  'UserType',
  'Gender',
  'CampaignSource',
  'BonusWallet',
  'Campaign',
  'Communication',
  'CommunicationProvider',
  'CommunicationChannel',
  'CommunicationType',
  'CommunicationActivityType',
  'CommunicationIdentifierStatus',
  'CouponGenerator',
  'CouponType',
  'CustomEvent',
  'IdentifierType',
  'Method',
  'Question',
  'Questionnaire',
  'TicketGenerator',
  'Unsubscribe',
  'UserStatus',
  'AppMetricaCabinet',
  'AppMetricaConnectionType',
  'LoyaltyCardLevel',
  'LoyaltyCardStatus',
  'LoyaltyCardType',
  'LoyaltyCity',
  'LoyaltyCurrency',
  'LoyaltyDeliveryType',
  'LoyaltyOrderStatus',
  'LoyaltyOrderType',
  'LoyaltyRegion',
  'CommunicationProviderType',
  'CommunicationTemplateType',
  'DocumentGroup',
  'DocumentStatus',
  'DocumentType',
  'LimitInterval',
  'Pixel',
  'Product',
  'ReceiptInCampaignStatus',
  'ReceiptStatus',
  'ReferralCodeSet',
  'ReferralGenerator'
];

@Injectable({ providedIn: 'root' })
export class LookupProvider extends Array<string> {
  constructor() {
    super(...LOOKUP_PROVIDERS);
  }

  addLookup(...name: string[]) {
    this.push(...name);
  }
}
