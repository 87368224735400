import { map, Observable } from 'rxjs';
import { TableFullDto } from './models';

export const getTableMetaByPath = (
  tablesMetadata$: Observable<TableFullDto[]>,
  fromTable: string,
  path: string[]
) =>
  tablesMetadata$.pipe(
    map((metadata) => {
      let currentMeta = metadata.find((m) => m.name === fromTable);
      if (currentMeta) {
        let accessingCollection = false;
        let accessingNavigation = false;
        for (const part of path) {
          const simpleColumnIndex = currentMeta.columns.findIndex(
            (c) => c.name === part
          );
          if (simpleColumnIndex >= 0) {
            return currentMeta.columns[simpleColumnIndex].type;
          }
          const collectionPropertyIndex = currentMeta.collections?.findIndex(
            (r) => r.name === part
          );
          if (
            currentMeta.collections &&
            collectionPropertyIndex !== undefined &&
            collectionPropertyIndex >= 0
          ) {
            accessingCollection = true;
            const collectionTargetTable: string =
              currentMeta.collections[collectionPropertyIndex].targetTable;
            currentMeta = metadata.find(
              (m) => m.name === collectionTargetTable
            );
            if (!currentMeta) {
              return null;
            }
            continue;
          }
          const navPropertyIndex = currentMeta.properties?.findIndex(
            (r) => r.name === part
          ) as number | undefined;
          if (
            currentMeta?.properties &&
            navPropertyIndex !== undefined &&
            navPropertyIndex >= 0
          ) {
            accessingCollection = false;
            accessingNavigation = true;
            currentMeta = metadata.find(
              (m) =>
                m.name ===
                (currentMeta?.properties &&
                  currentMeta?.properties[navPropertyIndex].targetTable)
            );
            if (!currentMeta) {
              return null;
            }
            continue;
          }
          return null;
        }
        return { meta: currentMeta, accessingCollection, accessingNavigation };
      } else {
        return null;
      }
    })
  );
