import { extractObjectParams } from './extractors';
import { MetastoreOperator, MetastoreSelectRequest } from './models';

export interface PartialBuilder extends MetastoreSelectRequest {
  select(columns: (string | Record<string, any>)[]): PartialBuilder;
  selectDistinct(columns: (string | Record<string, any>)[]): PartialBuilder;
  orderBy(col: string, dir: 'asc' | 'desc'): PartialBuilder;
  where(conditions: Record<string, any> | undefined): PartialBuilder;
  paginate(page: number, perPage: number): PartialBuilder;
  done(): MetastoreSelectRequest;
}

export const from = (entityName: string): PartialBuilder => ({
  query: {
    $from: entityName
  },
  select(columns) {
    this.query.$select = columns;
    return this;
  },
  selectDistinct(columns) {
    if (this.query.$select) {
      delete this.query.$select;
    }
    this.query['$select $distinct'] = columns;
    return this;
  },
  where(conditions) {
    if (!conditions) {
      delete this.query.$where;
      return this;
    }
    if (Array.isArray(conditions)) {
      return this;
    } else {
      const { obj, parameters } = extractObjectParams(conditions);
      this.query.$where = obj;
      this.parameters = { ...this.parameters, ...parameters };
    }
    return this;
  },
  orderBy(col, dir) {
    this.query.$orderBy = [{ [col]: dir }];
    return this;
  },
  paginate(page: number, perPage: number) {
    this.query.$limit = '@limit';
    this.query.$offset = '@offset';
    this.parameters = {
      ...this.parameters,
      ...{ '@limit': perPage, '@offset': page * perPage }
    };
    return this;
  },
  done() {
    return {
      query: this.query,
      parameters: this.parameters
    };
  }
});

export const equal = (col: string, val: string | number | boolean) => ({
  [col]: { $eq: val }
});

export const notEqual = (col: string, val: string | number | boolean) => ({
  [col]: { $ne: val }
});

export const exist = (col: string, conditions: Record<string, any>) => ({
  [col]: {
    [MetastoreOperator.exist]: {
      $where: conditions
    }
  }
});

export const notExist = (col: string, conditions: Record<string, any>) => ({
  [col]: {
    [MetastoreOperator.notExist]: {
      $where: conditions
    }
  }
});

export const and = (...conditions: Record<string, any>[]) => ({
  $and: conditions
});

export const or = (...conditions: Record<string, any>[]) => ({
  $or: conditions
});

export const icContains = (col: string, val: string) => ({
  [col]: { '$contains $ic': val }
});

export const greaterThan = (col: string, val: string | number) => ({
  [col]: { $gt: val }
});

export const lessThanOrEqual = (col: string, val: string | number) => ({
  [col]: { $lte: val }
});

export const greaterThanOrEqual = (col: string, val: string | number) => ({
  [col]: { $gte: val }
});

export const startsWith = (col: string, val: string) => ({
  [col]: { [MetastoreOperator.startsWith]: val }
});

export const isNull = (col: string) => ({
  [col]: { [MetastoreOperator.is]: null }
});

export const isNotNull = (col: string) => ({
  [col]: { [MetastoreOperator.isNot]: null }
});
