import { AggregationOperator } from './models';

export const extractPropertyPathFromSelect = (
  field: string | Record<string, any>
) => {
  if (typeof field === 'string') {
    return field.split('.');
  }
  const firstKey = Object.keys(field)[0];
  if (
    Object.values(AggregationOperator).includes(firstKey as AggregationOperator)
  ) {
    return (Object.values(field)[0] as string).split('.');
  } else {
    return (Object.values(Object.values(field)[0])[0] as string).split('.');
  }
};

export const setPropertyPathOnSelect = (
  field: string | Record<string, any>,
  path: string
) => {
  if (typeof field === 'string') {
    return path;
  }
  const firstKey = Object.keys(field)[0];
  if (
    Object.values(AggregationOperator).includes(firstKey as AggregationOperator)
  ) {
    return {
      [firstKey]: path
    };
  } else {
    return {
      [firstKey]: {
        [Object.keys(Object.values(field)[0])[0]]: path
      }
    };
  }
};
