@if ((loading$ | async) === false) {
  <div
    class="knk-expression-builder"
    [ngClass]="{ overflow: root }"
  >

    @if (isBinaryExpression$ | async) {
      <knk-binary-expression
        [metadataProvider]="metadataProvider"
        [expression]="(expression$ | async)!"
        [entityName]="(entityName$ | async)!"
        [pathFromRoot]="pathFromRoot"
        [afterControlTemplate]="afterControlTemplate"
        [controlSelector]="controlSelector"
        (expressionChanged)="updateExpression($event)"
        (removed)="remove()"
      ></knk-binary-expression>
    }

    @if (isGroupingExpression$ | async) {
      <knk-expression-group
        [metadataProvider]="metadataProvider"
        [entityName]="(entityName$ | async)!"
        [pathFromRoot]="pathFromRoot"
        [afterControlTemplate]="afterControlTemplate"
        [beforeControlTemplate]="beforeControlTemplate"
        [controlSelector]="controlSelector"
        [expression]="$any(expression$ | async)!"
        (expressionChanged)="updateExpression($event)"
        (removed)="remove()"
      ></knk-expression-group>
    }

    @if (isExistExpression$ | async) {
      <knk-exist-expression
        [metadataProvider]="metadataProvider"
        [entityName]="(entityName$ | async)!"
        [expression]="(expression$ | async)!"
        [afterControlTemplate]="afterControlTemplate"
        [beforeControlTemplate]="beforeControlTemplate"
        [controlSelector]="controlSelector"
        [pathFromRoot]="pathFromRoot"
        (expressionChanged)="updateExpression($event)"
        (removed)="remove()"
      ></knk-exist-expression>
    }

    @if (root) {
      <div class="knk-expression-builder__condition-controls knk-root-buttons space-y-2">
        <div class="flex gap-2 justify-start">
          @if (!!!(isGroupingExpression$ | async)) {
          <button
            tuiButton
            type="button"
            appearance="outline"
            size="s"
            (click)="addCondition()"
            data-testId="root-plus-condition"
          >
            <tui-svg src="tuiIconPlus" class="mr-1"></tui-svg>
          {{ 'filters.condition' | translate }}
          </button>
          }

          @if (!!!(isGroupingExpression$ | async)) {
          <button
            tuiButton
            type="button"
            appearance="outline"
            size="s"
            (click)="addGroup()"
            data-testId="root-plus-group"
          >
            <tui-svg src="tuiIconFolderPlus" class="mr-1"></tui-svg>
          {{ 'filters.group' | translate }}
          </button>
          }
        </div>
      </div>
    }


    <div tuiGroup>
      @if (showApply) {
        <button class="tui-group__auto-width-item" size="m" tuiButton (click)="apply()">
          {{ 'filters.apply' | translate }}
        </button>
      }

      @if (isShowReset) {
        <button
          class="tui-group__auto-width-item"
          size="m"
          tuiIconButton
          [tuiHint]="'ResetFilters' | translate"
          icon="tuiIconRefreshCcw"
          (click)="resetFilter()"
        ></button>
      }

      @if (showSave) {
        <button
          class="tui-group__auto-width-item"
          size="m"
          (click)="onSaveClick()"
          tuiIconButton
          [tuiHint]="saveHint"
          icon="tuiIconSave"
        ></button>
      }
    </div>
  </div>
} @else {
  <tui-loader size="m"></tui-loader>
}

