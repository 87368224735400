import { ModuleWithProviders, NgModule } from '@angular/core';
import { MetastoreService } from './metastore.service';

@NgModule({})
export class MetastoreModule {
  static forRoot(): ModuleWithProviders<MetastoreModule> {
    return {
      ngModule: MetastoreModule,
      providers: [MetastoreService]
    };
  }
}
