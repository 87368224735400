import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  TemplateRef
} from '@angular/core';
import { isDate } from 'date-fns';
import { slice } from 'lodash-es';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  first,
  map,
  Observable,
  of,
  Subject,
  switchMap
} from 'rxjs';
import {
  dataTypeToOperator,
  DYNAMIC_DATE_REGEX,
  existOptions,
  IN_QUANTITY,
  operatorToString
} from '../../lib/constants';
import { getTableMetaByPath } from '../../lib/metadata-utils';
import { DataType, MetastoreOperator, TableFullDto } from '../../lib/models';
import {
  extractBinaryValue,
  extractOperator,
  extractPropertyPath
} from '../../lib/where-helpers';
import { LookupProvider } from '../../lookup-provider.token';

@Component({
  selector: 'knk-binary-expression[expression][entityName][metadataProvider]',
  templateUrl: 'binary-expression.component.html',
  styleUrls: ['binary-expression.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BinaryExpressionComponent implements OnDestroy {
  private readonly lookups = inject(LookupProvider);

  _expression$ = new BehaviorSubject<Record<string, any>>({});

  @Input() set expression(val: Record<string, any>) {
    this._expression$.next(val);
  }

  private readonly _entityName$ = new BehaviorSubject<string>('');

  @Input() set entityName(val: string) {
    this._entityName$.next(val);
  }

  @Input() metadataProvider!: Observable<TableFullDto[]>;

  @Input() pathFromRoot: string | undefined;

  @Input() beforeControlTemplate:
    | TemplateRef<{ $implicit: BinaryExpressionComponent }>
    | undefined;

  @Input() afterControlTemplate:
    | TemplateRef<{ $implicit: BinaryExpressionComponent }>
    | undefined;

  @Input() controlSelector:
    | ((
        comp: BinaryExpressionComponent,
        type: DataType | 'lookup'
      ) => TemplateRef<{ $implicit: BinaryExpressionComponent }> | undefined)
    | undefined;

  @Output() expressionChanged = new EventEmitter<Record<string, any>>();

  @Output() removed = new EventEmitter<void>();

  propertyPath$ = this._expression$.pipe(
    map((expression) => extractPropertyPath(expression))
  );

  removeIdIfPropertyPathToLookup$ = this.propertyPath$.pipe(
    switchMap((path) =>
      getTableMetaByPath(
        this.metadataProvider,
        this._entityName$.getValue(),
        path.slice(0, path.length - 1)
      ).pipe(
        map((metadata) => {
          if (
            typeof metadata === 'object' &&
            metadata?.meta.name &&
            this.lookups.includes(metadata?.meta.name) &&
            metadata.meta.name !== this._entityName$.getValue()
          ) {
            return path.slice(0, path.length - 1);
          }
          return path;
        })
      )
    )
  );

  get propertyPathSync() {
    return extractPropertyPath(this._expression$.getValue());
  }

  expressionValue$ = this._expression$.pipe(
    map((expression) => extractBinaryValue(expression))
  );

  get expressionValueSync() {
    return extractBinaryValue(this._expression$.getValue());
  }

  expressionOperator$ = this._expression$.pipe(
    map((expression) => extractOperator(expression))
  );

  get expressionOperatorSync() {
    return extractOperator(this._expression$.getValue());
  }

  get parameterName() {
    return `${
      this.pathFromRoot ? `${this.pathFromRoot}:` : ''
    }${this.propertyPathSync.join('.')}:${this.expressionOperatorSync}`;
  }

  showValueControl$ = this.expressionOperator$.pipe(
    map(
      (op) =>
        ![MetastoreOperator.is, MetastoreOperator.isNot].includes(
          op as MetastoreOperator
        )
    )
  );

  destroy$ = new Subject<void>();

  optionsByIndex$ = combineLatest([this.propertyPath$, this._entityName$]).pipe(
    switchMap(([propertyPath, entityName]) =>
      (propertyPath.length
        ? combineLatest(
            propertyPath
              .map((_, index) => slice(propertyPath, 0, index))
              .map((slicedPath) =>
                getTableMetaByPath(
                  this.metadataProvider,
                  entityName,
                  slicedPath
                )
              )
          )
        : of([] as [])
      ).pipe(
        map((tableOrProperties) =>
          tableOrProperties.map((tableOrProperty) => {
            if (
              tableOrProperty === null ||
              typeof tableOrProperty === 'number'
            ) {
              return [];
            }
            const resultOptions = [
              {
                label: 'Columns',
                children: tableOrProperty.meta.columns.map((c) => ({
                  value: c.name,
                  label: c.name
                }))
              }
            ];

            if (tableOrProperty.meta.properties?.length) {
              resultOptions.push({
                label: 'Properties',
                children: tableOrProperty.meta.properties?.map((r) => ({
                  value: r.name,
                  label: r.name
                }))
              });
            }

            if (tableOrProperty.meta.collections?.length) {
              resultOptions.push({
                label: 'Collections',
                children: tableOrProperty.meta.collections?.map((r) => ({
                  value: r.name,
                  label: r.name
                }))
              });
            }
            return resultOptions;
          })
        )
      )
    )
  );

  operatorOptions$ = combineLatest([
    this.removeIdIfPropertyPathToLookup$,
    this._entityName$
  ]).pipe(
    switchMap(([propertyPath, entityName]) =>
      getTableMetaByPath(this.metadataProvider, entityName, propertyPath).pipe(
        map((res) => ({ res, tableName: this.entityName }))
      )
    ),
    map(({ res, tableName }) => {
      if (res === null) {
        return [];
      }
      if (typeof res === 'number') {
        return [
          {
            label: 'Operators',
            children: dataTypeToOperator[res].map((op) => ({
              value: op,
              label: operatorToString[op]
            }))
          }
        ];
      }
      if (res.accessingCollection) {
        return [{ label: 'Operators', children: existOptions }];
      }
      if (this.lookups.includes(res.meta.name) && tableName !== res.meta.name) {
        return [
          {
            label: 'Operators',
            children: [
              {
                value: MetastoreOperator.eq,
                label: operatorToString[MetastoreOperator.eq],
                isOperator: true
              },
              {
                value: MetastoreOperator.ne,
                label: operatorToString[MetastoreOperator.ne],
                isOperator: true
              }
            ]
          }
        ];
      }
      return [];
    })
  );

  lookupTableName$ = combineLatest([
    this.removeIdIfPropertyPathToLookup$,
    this._entityName$
  ]).pipe(
    switchMap(([path, tableName]) =>
      getTableMetaByPath(this.metadataProvider, tableName, path)
    ),
    map((metaOrDataType) => {
      if (metaOrDataType === null) {
        return null;
      }
      if (typeof metaOrDataType === 'number') {
        return null;
      }
      if (this.lookups.includes(metaOrDataType.meta.name)) {
        return metaOrDataType.meta.name;
      }
      return null;
    }),
    distinctUntilChanged()
  );

  currentSimpleExpressionType$ = combineLatest([
    this.propertyPath$,
    this._entityName$
  ]).pipe(
    switchMap(([path, tableName]) =>
      getTableMetaByPath(this.metadataProvider, tableName, path)
    ),
    map((tableOrProperty) =>
      typeof tableOrProperty === 'number' ? tableOrProperty : null
    )
  );

  optionsOnEndWithOperators$ = combineLatest([
    this.propertyPath$,
    this._entityName$
  ]).pipe(
    switchMap(([propertyPath, tableName]) =>
      getTableMetaByPath(this.metadataProvider, tableName, propertyPath).pipe(
        map((res) => ({ res, tableName }))
      )
    ),
    map(({ res, tableName }) => {
      if (res === null) {
        return [];
      }
      if (typeof res === 'number') {
        return [
          {
            label: 'Operators',
            children: dataTypeToOperator[res].map((op) => ({
              value: op,
              label: operatorToString[op]
            }))
          }
        ];
      }

      if (this.lookups.includes(res.meta.name) && res.meta.name !== tableName) {
        return [
          {
            label: 'Operators',
            children: [
              {
                value: MetastoreOperator.eq,
                label: operatorToString[MetastoreOperator.eq],
                isOperator: true
              },
              {
                value: MetastoreOperator.ne,
                label: operatorToString[MetastoreOperator.ne],
                isOperator: true
              }
            ]
          }
        ];
      }
      const columnsWithFkToLookup =
        res.meta.properties
          ?.filter((r) => this.lookups.includes(r.targetTable))
          ?.map((c) => c.baseColumn) ?? [];

      const resultOptions = [];

      if (res.accessingCollection) {
        resultOptions.push({
          label: 'Operators',
          children: existOptions
        });
      }

      resultOptions.push({
        label: 'Columns',
        children: res.meta.columns
          .filter((c) => !columnsWithFkToLookup.includes(c.name))
          .map((c) => ({
            value: c.name,
            label: c.name
          }))
      });

      if (res.meta.collections?.length) {
        resultOptions.push({
          label: 'Collections',
          children: res.meta.collections?.map((r) => ({
            value: r.name,
            label: r.name
          }))
        });
      }

      if (res.meta.properties?.length) {
        resultOptions.push({
          label: 'Properties',
          children: res.meta.properties?.map((r) => ({
            value: r.name,
            label: r.name
          }))
        });
      }

      return resultOptions;
    })
  );

  dateValueTypeOptions = [
    { value: 'dynamic', label: 'some time ago' },
    { value: 'static', label: 'exact date' }
  ];

  selectedDateValueType$ = this.expressionValue$.pipe(
    map((val) => {
      if (
        val &&
        typeof val === 'string' &&
        [...(val as string).matchAll(DYNAMIC_DATE_REGEX)].length
      ) {
        return 'dynamic';
      }
      return 'static';
    })
  );

  intervalOptions = [
    { value: 'seconds', label: 'seconds' },
    { value: 'minutes', label: 'minutes' },
    { value: 'hours', label: 'hours' },
    { value: 'days', label: 'days' },
    { value: 'months', label: 'months' },
    { value: 'years', label: 'years' }
  ];

  dynamicConfig$ = this.expressionValue$.pipe(
    map((v) => {
      if (!!v && typeof v === 'string' && v.includes('$utcnow')) {
        const match = [...(v as string).matchAll(DYNAMIC_DATE_REGEX)][0].groups;
        return {
          interval: match && match['interval'] ? match['interval'] : 'days',
          count: parseInt(match && match['count'] ? match['count'] : '1', 10)
        };
      }
      return null;
    })
  );

  get dynamicConfigSync() {
    const value = this.expressionValueSync;
    if (!!value && typeof value === 'string' && value.includes('$utcnow')) {
      const match = [...(value as string).matchAll(DYNAMIC_DATE_REGEX)][0];
      return {
        interval: match[0],
        count: parseInt(match[1], 10)
      };
    }
    return null;
  }

  DataType = DataType;

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  updatePropertyOnIndex(property: string, index: number) {
    const newPath = [...this.propertyPathSync];
    newPath.splice(index);
    newPath.push(property);
    this.expressionChanged.emit({
      [newPath.join('.')]: {}
    });
  }

  pushPropertyToEnd(property: string) {
    const newPath = [...this.propertyPathSync, property];
    this.expressionChanged.emit({
      [newPath.join('.')]: this.expressionOperatorSync
        ? {
            [this.expressionOperatorSync]: this.expressionValueSync
          }
        : {}
    });
  }

  updateOperator(newOperator: MetastoreOperator | typeof IN_QUANTITY) {
    if (newOperator === IN_QUANTITY) {
      const newExistExpr = {
        [this.propertyPathSync.join('.')]: {
          [MetastoreOperator.exist]: {
            $having: {
              $count: {
                Id: {}
              }
            }
          }
        }
      };
      this.expressionChanged.emit(newExistExpr);
      return;
    }
    if (
      [MetastoreOperator.exist, MetastoreOperator.notExist].includes(
        newOperator
      )
    ) {
      this.expressionChanged.emit({
        [this.propertyPathSync.join('.')]: {
          [newOperator]: true
        }
      });
      return;
    }
    this.currentSimpleExpressionType$.pipe(first()).subscribe((res) => {
      if (res && res === DataType.DateTime) {
        this.expressionChanged.emit({
          [this.propertyPathSync.join('.')]: {
            [newOperator]: new Date()
          }
        });
        return;
      }
      if (res && [DataType.Guid, DataType.String].includes(res)) {
        this.expressionChanged.emit({
          [this.propertyPathSync.join('.')]: {
            [newOperator]: ''
          }
        });
        return;
      }
      if (
        res &&
        [
          DataType.Double,
          DataType.Enum,
          DataType.Int,
          DataType.Long,
          DataType.Numeric
        ].includes(res)
      ) {
        this.expressionChanged.emit({
          [this.propertyPathSync.join('.')]: {
            [newOperator]: 0
          }
        });
        return;
      }
      if (res && res === DataType.Bool) {
        this.expressionChanged.emit({
          [this.propertyPathSync.join('.')]: {
            [newOperator]: false
          }
        });
        return;
      }
      const newExpr = {
        [this.propertyPathSync.join('.')]: {
          [newOperator]: this.expressionValueSync ?? null
        }
      };
      this.expressionChanged.emit(newExpr);
    });
  }

  updateValue(newValue: string | number | boolean | null) {
    if (this.expressionOperatorSync) {
      if (isDate(newValue)) {
        this.expressionChanged.emit({
          [this.propertyPathSync.join('.')]: {
            [this.expressionOperatorSync]: newValue
          }
        });
        return;
      }
      this.expressionChanged.emit({
        [this.propertyPathSync.join('.')]: {
          [this.expressionOperatorSync]: newValue
        }
      });
    }
  }

  updateLookupValue(newValue: number) {
    if (
      this.expressionOperatorSync &&
      this.propertyPathSync[this.propertyPathSync.length - 1] !== 'Id'
    ) {
      this.expressionChanged.emit({
        [`${this.propertyPathSync.join('.')}.Id`]: {
          [this.expressionOperatorSync]: newValue
        }
      });
    } else if (this.expressionOperatorSync) {
      this.expressionChanged.emit({
        [this.propertyPathSync.join('.')]: {
          [this.expressionOperatorSync]: newValue
        }
      });
    }
  }

  remove() {
    this.removed.emit();
  }

  pushToFromOptionsOnEnd(
    value: string | MetastoreOperator | typeof IN_QUANTITY
  ) {
    if (
      Object.values(MetastoreOperator).includes(value as MetastoreOperator) ||
      value === IN_QUANTITY
    ) {
      if (value === MetastoreOperator.is || value === MetastoreOperator.isNot) {
        this.expressionChanged.emit({
          [this.propertyPathSync.join('.')]: {
            [value]: null
          }
        });
        return;
      }
      this.updateOperator(value as MetastoreOperator);
      return;
    }
    this.pushPropertyToEnd(value);
  }

  updateValueForDynamicDate(type: string) {
    if (type === 'static') {
      if (this.expressionOperatorSync) {
        this.expressionChanged.emit({
          [this.propertyPathSync.join('.')]: {
            [this.expressionOperatorSync]: new Date()
          }
        });
      }
    }
    if (type === 'dynamic') {
      if (this.expressionOperatorSync) {
        this.expressionChanged.emit({
          [this.propertyPathSync.join('.')]: {
            [this.expressionOperatorSync]: `$utcnow - $days:1`
          }
        });
      }
    }
  }

  updateDynamicDateInterval(newInterval: string) {
    this.dynamicConfig$.pipe(first()).subscribe((res) => {
      if (this.expressionOperatorSync) {
        this.expressionChanged.emit({
          [this.propertyPathSync.join('.')]: {
            [this.expressionOperatorSync]:
              res && res.count
                ? `$utcnow - $${newInterval}:${res.count}`
                : `$utcnow - $${newInterval}:1`
          }
        });
      }
    });
  }

  updateDynamicDateCount(count: number) {
    this.dynamicConfig$.pipe(first()).subscribe((res) => {
      if (this.expressionOperatorSync) {
        this.expressionChanged.emit({
          [this.propertyPathSync.join('.')]: {
            [this.expressionOperatorSync]:
              res && res.interval
                ? `$utcnow - $${res.interval}:${count}`
                : `$utcnow - $days:${count}`
          }
        });
      }
    });
  }
}
